.home-page {
  &__banner {
    height: calc(100vh - 150px);
    color: white;
  }

  &__blogs {
    height: 100vh;
    background-color: $primary-100;

    @include media-breakpoint-down(xl) {
      height: auto;
    }
  }

  &__products {
    @include media-breakpoint-down(lg) {
      height: auto;

      &__container {
        flex-direction: column;
      }
    }
  }

  &__product {
    padding: 0;
    width: 100%;
    max-width: 30rem;
    aspect-ratio: 5 / 4;
    box-shadow: 0 8px 50px -6px #00000020;
    color: #212A4B;
    border-radius: .25rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;

    span {
      color: $gray-400;
      font-size: 0.75rem;
      letter-spacing: 1px;
    }

    &__description {
      width: 12vw;

      @include media-breakpoint-down(lg) {
        min-width: 10rem;
      }
    }

    &__center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;

      span {
        font-weight: 600;
        color: $primary;
        letter-spacing: 1px;
      }
    }

    &__image {
      width: 25vw;
      max-width: 18rem;
    }
  }
}
