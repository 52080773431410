@import "animations";

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fit {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.max-width-100 {
  max-width: 100%;
}

.z-index-1n {
  z-index: -1;
}

.opacity-75 {
  opacity: .75;
}

.opacity-50 {
  opacity: .50;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.bg-darkblue {
  background-color: $darkblue;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.w-10 {
  width: 10%;
}

.top-25 {
  top: 25%;
}
