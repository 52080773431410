.service-index-page{
  &__category {
    &__image {
      width: 10vw;
      height: 10vw;
      max-width: 100px;
      max-height: 100px;

      img {
        object-fit: contain;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      min-height: 2.125rem;
      padding-top: .2rem;
      padding-left: 2.5rem;
      margin-bottom: 1rem;
      background: url('../img/circle-icon.png') no-repeat left center;
      vertical-align: middle;
    }
  }
}
