// TRANSITIONS

// Fade
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

// Fade without vue transition (For the loading screen)
.fade-active {
  transition: opacity 1s;
  opacity: 1;
}

.fade-hide {
  opacity: 0;
}

// ANIMATIONS
@keyframes slide-right {
  0% {
    background-position-x: left;
  }
  100% {
    background-position-x: right;
  }
}

@keyframes stars-move {
  0% {
    transform: translateZ(-400px) rotate(0deg);
  }
  100%{
    transform: translateZ(500px) rotate(0deg);
  }
}

@keyframes stars-fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
