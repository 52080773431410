.arc-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 600px;
  background-size: 100% 95%;
  background-repeat: no-repeat;
  z-index: -1;

  @include media-breakpoint-down(xl) {
    background-size: cover;
    background-position-x: 50%;
  }
}
