.nebula-cover {
  background: black;
  overflow: hidden;

  canvas {
    position: absolute;
    transition: 5s all ease-in-out;
    transform-origin: 70% 50%;
  }

  &.hyperspace {
    canvas {
      transition: 15s all ease-in-out;
      transform: scale(1.5);
    }

    .stars-scene {
      perspective: 10px;
    }
  }

  .mobile-info {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

$animation-duration: 30s;
.stars-scene {
  perspective-origin: 50% 50%;
  position: absolute;
  opacity: 0.8;
  transition: 3s all;
  perspective: 400px;
}

.wrap {
  position: absolute;
  width: 1000px;
  height: 1000px;
  left: -500px;
  top: -500px;
  transform-style: preserve-3d;
  animation: stars-move $animation-duration infinite linear;
  animation-fill-mode: forwards;

  &:nth-child(2) {
    animation-delay: $animation-duration / 2;

    .wall {
      animation-delay: $animation-duration / 2;
    }
  }
}

.wall {
  background: url("~@/img/cover/stars.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  animation: stars-fade $animation-duration infinite linear;

  &.wall-right {
    transform: rotateY(90deg) translateZ(500px);
  }

  &.wall-left {
    transform: rotateY(-90deg) translateZ(500px);
  }

  &.wall-top {
    transform: rotateX(90deg) translateZ(500px);
  }

  &.wall-bottom {
    transform: rotateX(-90deg) translateZ(500px);
  }
}
