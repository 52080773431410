.clients {
  a {
    filter: grayscale(100%);
    opacity: 0.7;

    img {
      height: 100%;
    }
  }
}
