.blog-page {
  &__header {
    height: 30rem;
  }

  &__arc {
    bottom: -2px;
  }

  &__background-top {
    height: 40rem;
  }

  &__author {
    img {
      width: 3rem;
      height: 3rem;
    }
  }

  &__author-bottom {
    img {
      width: 3rem;
      height: 3rem;
    }
  }
}
