.logo-navbar {
  height: 4rem;

  @include media-breakpoint-down(sm) {
    height: 3rem;
  }
}

.navbar-toggler {
  border: none;
}
