.stars-cover {
  position: absolute;
  width: 100vw;
  height: 600px;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;

  img {
    width: 100%;
    object-fit: cover;
    align-self: flex-end;

    @include media-breakpoint-down(xxl) {
      height: 100%;
    }
  }
}
