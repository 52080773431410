// Bootstrap and other libraries should be imported in vendor.scss in order to create a different chunk for caching purposes
// If we import bootstrap here as well we would have duplicated css increasing the page loading time,
// however, the variables scss is shared between the app and the libraries, therefore, you can use it to override variables like:
// bootstrap $primary, $secondary, $grid-gutter-width, etc.
@import "variables";
@import "utilities";

// Imports required to use bootstrap variables that are not overridden by _variables.scss
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "pages";
@import "components";
@import "typography";

html {
  width: 100%;
  height: 100%;
  font-size: 16px; // 1rem
}

body {
  width: 100%;
  height: 100%;

  &.under-development {
    display: flex;
    align-items: center;
    background-image: url("../img/helix-nebula.png");
    background-size: cover;
    background-position: center;
    color: white;

    h2, h3 {
      text-shadow: 2px 2px 8px black;
    }

    .btn {
      width: 160px;
    }

    &:before {
      content: "";
      position: absolute;
      background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, rgba(88, 88, 88, 0.04) 35%, rgba(31, 30, 30, 0.58) 65%, #000000 100%);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &:after {
      content: "Image Credit: NASA/JPL-Caltech";
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0.5rem;
      opacity: 0.4;
    }
  }
}

.logo {
  height: 160px;
  background-image: url("../img/logo-helix-white.svg");
  background-position: center center;
  background-repeat: no-repeat;
  filter: drop-shadow(2px 2px 8px black);
}
