$footer-min-height: 500px;

.footer {
  position: relative;
  min-height: $footer-min-height;

  &__background {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 15% bottom;
    bottom: 0;
    left: 0;
    background-size: 275% 100%;
    z-index: -1;

    @include media-breakpoint-up(lg) {
      height: $footer-min-height;
      background-size: 80% 100%;
      background-position: left bottom;
    }
  }

  a {
    text-decoration: none;
  }

  &__menu {
    ul {
      list-style: none;
      padding: 0;

      li {
        padding: .5rem 0;
      }
    }

    a {
      color: $darkblue;
      opacity: 0.5;
    }
  }

  &__logo {
    img {
      height: 5rem;
    }
  }

  &__logos{
    img{
      height: 5rem;
      @include media-breakpoint-down(sm) {
        height: 3.5rem;
      }
    }
  }

  &__social-media {
    i {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background-color: $gray-400;
      color: $body-color;
      margin: 0 .5rem .5rem 0;
      font-size: 1.2rem;
    }
  }

  @include media-breakpoint-down(lg) {
    color: white;

    .footer__menu {
      a {
        color: white;
      }
    }

    &__social-media {
      i {
        color: white;
        background-color: #ffffff33;
      }
    }
  }
}
